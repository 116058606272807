import React from 'react'

const Flights = ({ flights }) => {
    return (
        <div>
            <center><h1>Ankünfte ZRH</h1></center>
            {flights.map((flight) => (
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{flight.municipality}, {flight.country} ({flight.iata})</h5>
                        {flight.state === 'Schengen' ? <h6 className="card-subtitle mb-2 text-success"><b>Schengen</b></h6> : <span></span> } 
                        {flight.state === 'EU' ? <h6 className="card-subtitle mb-2 text-warning"><b>EU</b></h6> : <span></span> } 
                        {flight.state === 'Drittstaat' ? <h6 className="card-subtitle mb-2 text-danger"><b>Non-Schengen</b></h6> : <span></span> }
                        <h6 className="card-subtitle mb-2 text-muted" >{flight.schengen === 'Schengen'}</h6>
                        {flight.vaccine ? <span class="badge badge-warning">Impfung</span> : <p></p>}
                        <h6 className="card-subtitle mb-2 text-muted"></h6>
                        <p className="card-text">Erwartet: {flight.arrival}</p>
                        <p className="card-text">{flight.isSchengen}</p>
                    </div>
                </div>
            ))
            }
        </div >
    )
};

export default Flights