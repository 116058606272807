import React, { Component } from 'react';
import Flights from './components/flights';
import moment from 'moment';

class App extends Component {
  render() {
    return (
      <Flights flights={this.state.flights} />
    )
  }

  state = {
    flights: []
  };

  vaccineObligatory = ['Netherlands'];
  base_url = 'https://opensky-network.org/api/';

  componentDidMount() {
    let begin = '1643673600';
    let end = moment().add(2, 'days').unix();
    let flightUrl = this.base_url + 'flights/arrival?airport=lszh&begin=' + begin + '&end=' + end;
    fetch(flightUrl)
      .then(res => res.json())
      .then((data) => {

        console.log('data', data);

        let json = [{
          "icao_code": "LPPT",
          "name": "Humberto Delgado Airport (Lisbon Portela Airport)",
          "latitude_deg": 38.7813,
          "longitude_deg": -9.13592,
          "continent": "EU",
          "iso_country": "PT",
          "country": "Portugal",
          "municipality": "Lisbon",
          "state": "Schengen",
          "iata_code": "LIS"
        },
        {
          "icao_code": "LPPR",
          "name": "Francisco de Sá Carneiro Airport",
          "latitude_deg": 41.24810028,
          "longitude_deg": -8.681389809,
          "continent": "EU",
          "iso_country": "PT",
          "country": "Portugal",
          "municipality": "Porto",
          "state": "Schengen",
          "iata_code": "OPO"
        }];

        let airportUrl = '/airport.json';

        fetch(airportUrl)
          .then(res2 => res2.json())
          .then((data2) => {

            console.log('airport', data2);

            data.forEach(flight => {
              let airport = data2.find(item => item.icao_code === flight.estDepartureAirport);
              if (airport !== undefined) {
                flight.airport = airport.name;
                flight.municipality = airport.municipality;
                flight.iata = airport.iata_code;
                flight.country = airport.iso_country;
                //flight.state = airport.state;
              }

              //flight.arrival = moment.unix(flight.lastSeen).format("MM/DD/YYYY");

            })
              .catch((err) => {
                console.error(err);
              });
          });
        this.setState({ flights: data })
      })
      .catch(console.log)
  }
}

export default App;